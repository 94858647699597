import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './i18n'; 

import FriendWaitGame from './pages/FriendWaitGame';
import RandomEndGame from './pages/RandomEndGame';
import RandomWaitGame from './pages/RandomWaitGame';
import InitialPage from './pages/InitialPage';
import Rules from './pages/Rules';
import Game from './pages/Game';
import PlayAgainWait from './pages/PlayAgainWait';
import GameNotExist from './pages/GameNotExist';
import Leaderboard from './pages/Leaderboard';
import { SocketProvider } from './contexts/WebSocketContext';
import AiWaitGame from './pages/AiWaitGame';
import Shop from './pages/Shop';
import AdsTest from './pages/AdsTest';

const App: React.FC = () => {
    useEffect(() => {
        const tg = window.Telegram?.WebApp;
        if (tg) {
            tg.expand();
        }
    }, []);
    
    return (
        <SocketProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<InitialPage />} />
                    <Route path="/friend-wait-game" element={<FriendWaitGame />} />
                    <Route path="/random-end-game" element={<RandomEndGame />} />
                    <Route path="/random-wait-game" element={<RandomWaitGame />} />
                    <Route path="/game-rules" element={<Rules />} />
                    <Route path="/play-again-wait" element={<PlayAgainWait />} />
                    <Route path="/game-not-exist" element={<GameNotExist />} />
                    <Route path="/leaderboard" element={<Leaderboard />} />
                    <Route path="/game" element={<Game />} />
                    <Route path="/ai-wait-game" element={<AiWaitGame />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/ads-test" element={<AdsTest />} />
                </Routes>
            </Router>
        </SocketProvider>
    );
};

export default App;
