import React from 'react';

interface SquareProps {
  black: boolean;
  children?: React.ReactNode;
  onClick: () => void;
  isSelected: boolean;
  isPossibleMove: boolean;
}

const Square: React.FC<SquareProps> = ({ black, children, onClick, isSelected, isPossibleMove }) => {
  const backgroundColor = black ? 'red' : 'white';
  const borderColor = isSelected ? 'blue' : 'gray';
  const highlightColor = isPossibleMove ? 'green' : backgroundColor;

  return (
    <div 
      style={{ 
        width: '50px', 
        height: '50px', 
        backgroundColor: highlightColor, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        border: `2px solid ${borderColor}`,
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Square;
