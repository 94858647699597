import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { io, Socket } from 'socket.io-client';

const SocketContext = createContext<Socket | null>(null);

interface SocketProviderProps {
    children: ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null);

    const connectSocket = () => {
        const socket = io('https://zizigame.net', {
            transports: ['websocket'],
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 5000,
        });

        socket.on('connect', () => {
            console.log('Socket.IO connection established I am global socket handler.');
            setSocket(socket);
        });

        socket.on('disconnect', (reason) => {
            console.log('Socket.IO connection closed:', reason);
        });
        
        socket.on('connect_error', (error) => {
            console.error('Socket.IO error:', error);
        });

        return socket;
    };

    useEffect(() => {
        const socket = connectSocket();

        const interval = setInterval(() => {
            if (socket.connected) {
                socket.emit('ping');
            }
        }, 30000);

        return () => {
            clearInterval(interval);
            socket.close();
        };
    }, []);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => {
    return useContext(SocketContext);
};
