import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from '../components/BackButton';
import { useTelegram } from '../hooks/useTelegram';
import './AiWaitGame.css';
import SimpleRedButton from '../components/SimpleRedButton';
import { useTranslation } from 'react-i18next';
import { useSocket } from '../contexts/WebSocketContext';

const AiWaitGame: React.FC = () => {
    const telegramData = useTelegram();
    const socket = useSocket(); // Получаем сокет из контекста
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (socket && telegramData.user) {
            const message = {
                type: 'play-ai',
                userId: telegramData.user.id,
                userName: telegramData.user.first_name,
            };
            socket.emit('play-ai', message);

            socket.on('start', (data) => {
                navigate('/game', { state: data });
            });

            // Очистка обработчика при размонтировании компонента
            return () => {
                socket.off('start');
            };
        }
    }, [socket, telegramData.user, navigate]);

    const handleCancelClick = () => {
        if (socket && telegramData.user) {
            const message = {
                type: 'play-ai-cancel',
                userId: telegramData.user.id,
            };
            socket.emit('play-ai-cancel', message);
        }
        navigate('/');
    };

    return (
        <div className="ai-wait-game">
            <div className="content">
                <h1 className='ai-wait-game-text'>{t('RandomWaitGame.searchingForOpponent')}</h1>
                <SimpleRedButton text={t('RandomWaitGame.cancelButton')} onClick={handleCancelClick} />
            </div>
            <BackButton onClickHandler={handleCancelClick}></BackButton>
        </div>
    );
};

export default AiWaitGame;
