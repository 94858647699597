import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from '../components/BackButton';
import SimpleButton from '../components/SimpleButton';
import { useTelegram } from '../hooks/useTelegram';
import './FriendWaitGame.css';
import SimpleRedButton from '../components/SimpleRedButton';
import { useTranslation } from 'react-i18next';
import { useSocket } from '../contexts/WebSocketContext';

const FriendWaitGame: React.FC = () => {
    const location = useLocation();
    const { gameId } = location.state || {};
    const telegramData = useTelegram();
    const socket = useSocket(); // Получаем сокет из контекста
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (socket && telegramData.user) {
            socket.emit('join', {
                userId: telegramData.user?.id,
                gameId: gameId || telegramData.gameId,
                userName: telegramData.user?.first_name
            });

            socket.on('start', (data: any) => {
                navigate('/game', { state: data });
            });

            socket.on('game-not-exist', () => {
                setErrorMessage("Game does not exist");
            });

            // Очистка обработчиков при размонтировании компонента
            return () => {
                socket.off('start');
                socket.off('game-not-exist');
            };
        }
    }, [socket, telegramData, navigate, gameId]);

    const handleCancelClick = () => {
        if (socket && telegramData.user) {
            socket.emit('friend-game-cancel', {
                userId: telegramData.user.id,
                gameId: gameId || telegramData.gameId,
            });
        }
        navigate('/');
    };

    const handlePlayClick = () => {
        var tg = window.Telegram?.WebApp;
        tg.initDataUnsafe.start_param = undefined;
        navigate('/');
    };

    return (
        <div className="friend-wait-game">
            <div className="content">
                {errorMessage ? (
                    <>
                        <p className="error-message">{errorMessage}</p>
                        <SimpleButton text={t('FriendWaitGame.menuButton')} onClick={handlePlayClick} />
                    </>
                ) : (
                    <>
                        <h1 className='friend-wait-text'>{t('FriendWaitGame.waitingForFriend')}</h1>
                        <SimpleRedButton text={t('FriendWaitGame.cancelButton')} onClick={handleCancelClick} />
                    </>
                )}
            </div>
            <BackButton onClickHandler={handleCancelClick}></BackButton>
        </div>
    );
}

export default FriendWaitGame;
