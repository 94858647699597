import axios from 'axios';
import { CreateGameResponse, LeaderboardResponse } from '../models/LeaderboardModels';

const API_URL = 'https://zizigame.net';
export const getLeaderboard = async (userId: number): Promise<LeaderboardResponse> => {
    try {
        const response = await axios.get<LeaderboardResponse>(`${API_URL}/api/leaderboard`, {
            params: { userId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching leaderboard:', error);
        throw error;
    }
};

export const createGame = async (userId: number): Promise<CreateGameResponse> => {
    try {
        const response = await axios.post<CreateGameResponse>(`${API_URL}/api/create-game`, { userId });
        return response.data;
    } catch (error) {
        console.error('Error creating game:', error);
        throw error;
    }
};

export const getInvoiceLink = async (userId: number): Promise<string> => {
    try {
        const response = await axios.post<{ invoiceLink: string }>(`${API_URL}/api/getInvoiceLink`, { userId });
        return response.data.invoiceLink;
    } catch (error) {
        console.error('Error fetching invoice link:', error);
        throw error;
    }
};