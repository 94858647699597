import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../components/SimpleButton';
import BackButton from '../components/BackButton';
import './Shop.css';
import { useTranslation } from 'react-i18next';
import CustomBackButton from '../components/CustomBackButton';
import { useTelegram } from '../hooks/useTelegram';
import { getInvoiceLink } from '../services/api';

const Shop: React.FC = () => {
    const navigate = useNavigate();
    const telegramData = useTelegram();
    const { t } = useTranslation();
    const [purchaseInfo, setPurchaseInfo] = useState<{ status: string, productTitle: string } | null>(null);

    const handleOkClick = () => {
        navigate('/');
    };

    const handleBuyClick = async () => {
        try {
            const userId = telegramData.user?.id;

            if (!userId) {
                console.error('User ID is not available');
                return;
            }

            // Получаем ссылку на инвойс
            const invoiceLink = await getInvoiceLink(userId);

            // Открываем инвойс и обрабатываем его закрытие
            window.Telegram.WebApp.openInvoice(invoiceLink, (status) => {
                // Обработка статуса инвойса
                if (status === 'paid') {
                    setPurchaseInfo({ status: 'Спасибо за покупку!', productTitle: 'Название продукта' });
                } else if (status === 'cancelled') {
                    setPurchaseInfo({ status: 'Покупка отменена.', productTitle: '' });
                } else if (status === 'failed') {
                    setPurchaseInfo({ status: 'Не удалось провести оплату.', productTitle: '' });
                } else if (status === 'pending') {
                    setPurchaseInfo({ status: 'Платеж в обработке...', productTitle: '' });
                }
            });
        } catch (error) {
            console.error('Error fetching invoice link:', error);
        }
    };

    return (
        <div className="shop-container">
            <BackButton />
            <CustomBackButton />
            <div className="shop-content">
                <SimpleButton text="Buy" onClick={handleBuyClick}></SimpleButton>
            </div>
            <SimpleButton text={t('Shop.okButton')} onClick={handleOkClick} />
            {purchaseInfo && (
                <div className="popup">
                    <h2>{purchaseInfo.status}</h2>
                    {purchaseInfo.productTitle && <p>{purchaseInfo.productTitle}</p>}
                    <SimpleButton text="Закрыть" onClick={() => setPurchaseInfo(null)} />
                </div>
            )}
        </div>
    );
};

export default Shop;
