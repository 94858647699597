import { useEffect, useState } from 'react';

interface TelegramUser {
    id: number;
    first_name: string;
    username?: string;
    photo_url?: string;
    language_code?: string;
}

interface TelegramWebApp {
    initDataUnsafe: {
        user?: TelegramUser;
        start_param?: string;
        query_id?: string;
    };
    BackButton: {
        show: () => void;
        hide: () => void;
        onClick: (callback: () => void) => void;
        offClick: (callback: () => void) => void;
    };
    close: () => void;
    openTelegramLink: (url: string) => void;
    expand: () => void;

    openInvoice: (url: string, callback?: (status: InvoiceStatus) => void) => void;
}

type InvoiceStatus = 'paid' | 'cancelled' | 'failed' | 'pending';

declare global {
    interface Window {
        Telegram: {
            WebApp: TelegramWebApp;
        };
    }
}

export function useTelegram() {
    const [telegramData, setTelegramData] = useState<{
        user?: TelegramUser;
        gameId?: string;
        queryId?: string;
    }>({
        user: undefined,
        gameId: undefined,
        queryId: undefined
    });

    useEffect(() => {
        const tg = window.Telegram?.WebApp;

        if (tg) {
            const user = tg.initDataUnsafe.user;
            const gameId = tg.initDataUnsafe.start_param;
            const queryId = tg.initDataUnsafe.query_id;

            setTelegramData({ user, gameId, queryId });
        } else {
            console.error('Telegram Web Apps API is not available');
        }
    }, []);

    return telegramData;
}
