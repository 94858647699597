import React from 'react';
import './SimpleRedButton.css';

interface SimpleRedButtonProps {
    text: string;
    onClick: () => void;
    disabled?: boolean;
    className?: string;
}

const SimpleRedButton: React.FC<SimpleRedButtonProps> = ({ text, onClick, disabled = false, className = '' }) => {
    const buttonClassName = `simple-red-button ${className}`.trim();

    return (
        <button className={buttonClassName} onClick={onClick} disabled={disabled}>
            {text}
        </button>
    );
};

export default SimpleRedButton;
