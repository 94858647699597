import React from 'react';
import './SimpleButton.css';

interface SimpleButtonProps {
    text: string;
    onClick: () => void;
    disabled?: boolean;
}

const SimpleButton: React.FC<SimpleButtonProps> = ({ text, onClick, disabled = false }) => {
    return (
        <button className="simple-button" onClick={onClick} disabled={disabled}>
            {text}
        </button>
    );
};

export default SimpleButton;
