import React, { useEffect } from 'react';
import { useAdsgram } from '../hooks/useAdsgram';
import HugeAdBanner from '../components/HugeAdBanner';

const AdsTest: React.FC = () => {
    return (
        <HugeAdBanner></HugeAdBanner>
    );
};

export default AdsTest;
