import React, { useEffect } from 'react';
import './HugeAdBanner.css';
import { useAdsgram } from '../hooks/useAdsgram';

const HugeAdBanner: React.FC = () => {
    const showAd = useAdsgram({
        blockId: '2574',
        onReward: () => {
            console.log('User watched the ad and received a reward.');
        },
        onError: (result) => {
            console.error('Ad failed to display:', result);
        },
    });

    useEffect(() => {
        showAd();
    }, [showAd]);

    return (
        <div className="huge-ad-banner">
        </div>
    );
};

export default HugeAdBanner;
