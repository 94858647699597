import React from 'react';

export type CheckerType = 'black' | 'white';
export type CheckerRank = 'man' | 'king';

interface CheckerProps {
  type: CheckerType;
  rank: CheckerRank;
  isMoveable: boolean;
}

const Checker: React.FC<CheckerProps> = ({ type, rank, isMoveable }) => {
  const color = type === 'black' ? 'black' : 'white';
  const king = rank === 'king' ? '👑' : '';
  const moveableIcon = isMoveable ? '🟢' : ''; // Зеленый кружок

  return (
    <div 
      style={{ 
        width: '40px', 
        height: '40px', 
        borderRadius: '50%', 
        backgroundColor: color, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        color: type === 'black' ? 'white' : 'black',
        fontSize: '20px',
        position: 'relative' // Позиционирование для размещения иконок
      }}
    >
      {king}
      {isMoveable && (
        <span 
          style={{ 
            position: 'absolute', 
            top: '0', 
            right: '0', 
            fontSize: '14px' 
          }}
        >
          {moveableIcon}
        </span>
      )}
    </div>
  );
};

export default Checker;
