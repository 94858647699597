import React, { useCallback, useEffect, useState } from 'react';
import { useTelegram } from '../hooks/useTelegram';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleRedButton from '../components/SimpleRedButton';
import { useTranslation } from 'react-i18next';
import SimpleButton from '../components/SimpleButton';
import { useSocket } from '../contexts/WebSocketContext';
import Square from '../components/game/Square';
import Checker from '../components/game/Checker';
import './Game.css';

interface Player {
    userId: number;
    userName: string;
    mark: number;
    score: number;
}

interface GameResult {
    type: string;
    score: {
        player: number;
        opponent: number;
    };
    duration: number;
    totalDots: number;
    result: 'win' | 'lose' | 'tie';
}

// Position interface for board coordinates
interface Position {
    x: number;
    y: number;
}

// Checker type for players
type CheckerType = 'white' | 'black';

// Checker rank indicating 'man' or 'king'
type CheckerRank = 'man' | 'king';

// Represents a single checker on the board
interface Checker {
    type: CheckerType;
    rank: CheckerRank;
}

// Represents the state of each square on the board
interface SquareState {
    checker: Checker | null; // A square can either have a checker or be empty
}

// Represents each player in the game
interface PlayerDto {
    userId: number;          // User ID of the player
    userName: string;        // Name of the player
    mark: number;       // Player's checker type (white or black)
    score: number;           // Player's score
}

// Interface for the start message
interface StartMessage {
    gameId: string;                 // Unique identifier for the game
    type: 'start';                  // Message type, fixed as 'start'
    players: PlayerDto[];           // Array of players in the game
    currentTurn: number;            // User ID of the player whose turn it is
    board: SquareState[][];              // Current state of the board
    possibleMoves: PossibleMoves;      // Possible moves available for the current turn
}

type PossibleMoves = {
    [key: string]: Position[]; // Key is "x_y" format and value is an array of Position objects
};

const Game: React.FC = () => {
    const { user, gameId } = useTelegram();
    const { t } = useTranslation();
    const socket = useSocket();
    const location = useLocation();
    const navigate = useNavigate();
    const [gameStarted, setGameStarted] = useState<boolean>(true);
    const [showEndGamePopup, setShowEndGamePopup] = useState<boolean>(false);
    const [showAskEndGamePopup, setShowAskEndGamePopup] = useState<boolean>(false);
    const [showLeavePopup, setShowLeavePopup] = useState<boolean>(false);
    const [playerScore, setPlayerScore] = useState<number>(0);
    const [opponentScore, setOpponentScore] = useState<number>(0);
    const [opponentDisconnected, setOpponentDisconnected] = useState<boolean>(false);
    const [messageSent, setMessageSent] = useState<boolean>(false);
    const [showResultPopup, setShowResultPopup] = useState<boolean>(false);
    const [resultMessage, setResultMessage] = useState<GameResult | null>(null);
    const [countdown, setCountdown] = useState<number | null>(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [reconnectTimer, setReconnectTimer] = useState<number | null>(null);
    const [showReconnectPopup, setShowReconnectPopup] = useState<boolean>(false);

    const initialGameState = location.state as StartMessage | undefined;

    const [stateGameId, setStateGameId] = useState<string | null>(initialGameState?.gameId || null);
    const [players, setPlayers] = useState<PlayerDto[]>(initialGameState?.players || []);
    const [currentTurn, setCurrentTurn] = useState<number | null>(initialGameState?.currentTurn || null);
    const [squares, setSquares] = useState<SquareState[][]>(initialGameState?.board || []);
    const [possibleMoves, setPossibleMoves] = useState<PossibleMoves | null>(initialGameState?.possibleMoves || null);
    const [selectedChecker, setSelectedChecker] = useState<Position | null>(null);

    const handleSquareClick = useCallback((x: number, y: number) => {
        if (currentTurn !== user?.id) return;

        const square = squares[y][x];
        const checker = square.checker;

        if (checker && checker.type === getCheckerTypeFromMark(players.find(p => p.userId === user?.id)?.mark)) {
            setSelectedChecker({ x, y });
        } else if (selectedChecker && possibleMoves) {
            // Check if the move is valid based on possible moves
            const validMoves = possibleMoves[`${selectedChecker.x}_${selectedChecker.y}`] || [];
            const isValidMove = validMoves.some(move => move.x === x && move.y === y);

            if (isValidMove && socket) {
                const newSquares = squares.map(row => row.map(cell => ({ ...cell })));
                newSquares[selectedChecker.y][selectedChecker.x].checker = null;
                newSquares[y][x].checker = squares[selectedChecker.y][selectedChecker.x].checker;

                setSquares(newSquares);

                setSelectedChecker({ x, y });

                // Emit the move event to the server
                socket.emit('move', {
                    xFrom: selectedChecker.x,
                    yFrom: selectedChecker.y,
                    xTo: x,
                    yTo: y,
                });
            }
        }
    }, [squares, currentTurn, selectedChecker, possibleMoves, socket, players, user?.id]);


    const renderSquare = (x: number, y: number) => {
        const square = squares[y][x];
        const isSelected = selectedChecker && selectedChecker.x === x && selectedChecker.y === y;
        const isPossibleMove = selectedChecker && possibleMoves
            ? possibleMoves[`${selectedChecker.x}_${selectedChecker.y}`]?.some(pos => pos.x === x && pos.y === y)
            : false;
    
        // Проверяем, может ли шашка сделать ход
        const isMoveable = possibleMoves 
            ? Object.keys(possibleMoves).some(key => {
                const [possibleX, possibleY] = key.split('_').map(Number);
                return possibleX === x && possibleY === y;
              })
            : false;
    
        return (
            <Square
                key={`${x}-${y}`}
                black={(x + y) % 2 === 1}
                onClick={() => handleSquareClick(x, y)}
                isSelected={!!isSelected}
                isPossibleMove={!!isPossibleMove}
            >
                {square.checker && (
                    <Checker 
                        type={square.checker.type} 
                        rank={square.checker.rank} 
                        isMoveable={isMoveable} // Передаем параметр isMoveable
                    />
                )}
            </Square>
        );
    };

    const getCheckerTypeFromMark = (mark?: number): CheckerType | null => {
        if (mark === 1) return 'white';
        if (mark === 2) return 'black';
        return null; // Handles undefined or any invalid mark values
    };

    useEffect(() => {
        if (socket) {
            if (socket && user && !messageSent) {
                socket.emit('join', {
                    userId: user.id,
                    gameId: gameId || stateGameId,
                    userName: user.first_name,
                });
                setMessageSent(true);
            }

            socket.on('ask-to-end-request', () => {
                setShowEndGamePopup(true);
            });

            socket.on('ask-to-end-decline-response', () => {
                setShowEndGamePopup(false);
            });

            socket.on('move-update', (message) => {
                const { board, currentTurn, possibleMoves, canContinue } = message;

                setSquares(board);
                setCurrentTurn(currentTurn);
                setPossibleMoves(possibleMoves);

                // Clear the selected checker unless the player can continue
                if (!canContinue) {
                    setSelectedChecker(null);
                }
            });

            socket.on('opponent-disconnected', () => {
                setOpponentDisconnected(true);
                setReconnectTimer(30);
            });

            socket.on('opponent-rejoin', () => {
                setOpponentDisconnected(false);
                setShowReconnectPopup(false);
                setReconnectTimer(null);
            });

            socket.on('rejoin', (message) => {
                const { players, currentTurn, possibleMoves, board } = message;

                setSquares(board);
                setPossibleMoves(possibleMoves);
                setPlayers(players);
                setCurrentTurn(currentTurn);

                const currentUser = players.find((p: { userId: number }) => p.userId === user?.id);
                const opponent = players.find((p: { userId: number }) => p.userId !== user?.id);

                if (currentUser) {
                    setPlayerScore(currentUser.score);
                }
                if (opponent) {
                    setOpponentScore(opponent.score);
                }

                setGameStarted(true);
                setShowEndGamePopup(false);
            });

            socket.on('game-end-win', (message) => {
                setResultMessage(message);
                setShowResultPopup(true);
            });

            socket.on('game-end-lose', (message) => {
                setResultMessage(message);
                setShowResultPopup(true);
            });

            socket.on('game-not-exist', () => {
                navigate('/game-not-exist');
            });

            socket.io.on('reconnect', () => {
                if (user) {
                    socket.emit('join', {
                        userId: user.id,
                        gameId: gameId || stateGameId,
                        userName: user.first_name,
                    });
                }
            });

            return () => {
                socket.off('ask-to-end-request');
                socket.off('ask-to-end-decline-response');
                socket.off('update');
                socket.off('opponent-disconnected');
                socket.off('opponent-rejoin');
                socket.off('rejoin');
                socket.off('game-end-win');
                socket.off('game-end-lose');
                socket.off('game-not-exist');
                socket.io.off('reconnect');
                socket.off('move-update');
            };
        }
    }, [socket, gameId, user, players, stateGameId, messageSent, navigate]);

    const handleResultPopupClose = () => {
        setShowResultPopup(false);
        if (resultMessage) {
            navigate('/random-end-game', { state: resultMessage });
        }
    };

    const handleEndGame = () => {
        setShowAskEndGamePopup(true);
    };

    const handleAskEndGame = () => {
        if (socket) {
            socket.emit('ask-to-end');
        }
        setShowAskEndGamePopup(false);
        setIsButtonDisabled(true);
        setCountdown(60);
    };

    const handleLeaveGame = () => {
        setShowLeavePopup(true);
    };

    const handleConfirmLeaveGame = () => {
        if (socket) {
            socket.emit('leave');
        }
        setShowLeavePopup(false);
    };

    const handlePopupYes = () => {
        if (socket) {
            socket.emit('ask-to-end-accept');
        }
        setShowEndGamePopup(false);
    };

    const handlePopupNo = () => {
        if (socket) {
            socket.emit('ask-to-end-decline');
        }
        setShowEndGamePopup(false);
    };

    const handlePopupCancel = () => {
        setShowAskEndGamePopup(false);
        setShowLeavePopup(false);
    };

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (countdown !== null && countdown > 0) {
            timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        } else if (countdown === 0) {
            setIsButtonDisabled(false);
            setCountdown(null);
        }

        return () => clearTimeout(timer);
    }, [countdown]);

    useEffect(() => {
        let reconnectInterval: NodeJS.Timeout;

        if (opponentDisconnected && reconnectTimer && reconnectTimer > 0) {
            setShowReconnectPopup(true);
            reconnectInterval = setTimeout(() => setReconnectTimer(reconnectTimer - 1), 1000);
        } else if (reconnectTimer === 0) {
            if (socket) {
                socket.emit('player-reconnect-time-out');
            }
            setOpponentDisconnected(false);
            setShowReconnectPopup(false);
        }

        return () => clearTimeout(reconnectInterval);
    }, [reconnectTimer, opponentDisconnected, socket]);

    const isPopupOpen = showEndGamePopup || showResultPopup || showAskEndGamePopup || showLeavePopup || showReconnectPopup;

    const getTurnText = () => {
        const isYourTurn = currentTurn === user?.id;
        const playerMark = players.find(p => p.userId === currentTurn)?.mark;
        return {
            text: isYourTurn ? t('Game.yourTurn') : t('Game.opponentTurn'),
            color: playerMark === 1 ? 'red' : 'blue',
            additionalClass: isYourTurn ? 'your-turn-text' : ''
        };
    };

    const turnInfo = getTurnText();
    return (
        <div>
            <div className="board-page">
                <div className="board-header">
                    <h2>Current Turn: {turnInfo.text}</h2>
                </div>
                <div className="board-container">
                    {squares.map((row, y) => (
                        <div className="board-row" key={y}>
                            {row.map((_, x) => renderSquare(x, y))}
                        </div>
                    ))}
                </div>
            </div>

            <div className="center-buttons">
                <SimpleRedButton
                    text={isButtonDisabled ? t('Game.wait', { countdown }) : t('Game.endGame')}
                    onClick={handleEndGame}
                    disabled={isButtonDisabled}
                />
                <SimpleRedButton className='leave-button' text={t('Game.leave')} onClick={handleLeaveGame} />
            </div>

            {isPopupOpen && <div className="popup-overlay"></div>}

            {showEndGamePopup && (
                <div className="popup">
                    <button className="close-button" onClick={handlePopupNo}></button>
                    <div className="popup-content">
                        <p>{t('Game.askToEndGame', { result: playerScore > opponentScore ? t('Game.win') : playerScore === opponentScore ? t('Game.tie') : t('Game.lose') })}</p>
                        <div className='popup-buttons-container'>
                            <SimpleRedButton text={t('Game.yes')} onClick={handlePopupYes} />
                            <SimpleButton text={t('Game.no')} onClick={handlePopupNo} />
                        </div>
                    </div>
                </div>
            )}

            {showResultPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <p>
                            {resultMessage?.result === 'win'
                                ? t('Game.youWin')
                                : resultMessage?.result === 'lose'
                                    ? t('Game.youLose')
                                    : t('Game.itsATie')}
                        </p>

                        <SimpleButton text={t('Game.okTitle')} onClick={handleResultPopupClose} />
                    </div>
                </div>
            )}

            {showAskEndGamePopup && (
                <div className="popup">
                    <button className="close-button" onClick={handlePopupCancel}></button>
                    <div className="popup-content">
                        <p>{t('Game.askEndGame', { result: playerScore > opponentScore ? t('Game.win') : playerScore === opponentScore ? t('Game.tie') : t('Game.lose') })}</p>
                        <div className='popup-buttons-container'>
                            <SimpleRedButton text={t('Game.ask')} onClick={handleAskEndGame} />
                            <SimpleButton text={t('Game.cancel')} onClick={handlePopupCancel} />
                        </div>
                    </div>
                </div>
            )}

            {showLeavePopup && (
                <div className="popup">
                    <button className="close-button" onClick={handlePopupCancel}></button>
                    <div className="popup-content">
                        <p>{t('Game.confirmLeave')}</p>
                        <div className='popup-buttons-container'>
                            <SimpleRedButton text={t('Game.leave')} onClick={handleConfirmLeaveGame} />
                            <SimpleButton text={t('Game.cancel')} onClick={handlePopupCancel} />
                        </div>
                    </div>
                </div>
            )}

            {showReconnectPopup && reconnectTimer !== null && (
                <div className="popup">
                    <div className="popup-content">
                        <p>{t('Game.waitingForReconnection')}</p>
                        <p>{t('Game.timeRemaining', { time: reconnectTimer < 10 ? `0${reconnectTimer}` : reconnectTimer })}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Game;
